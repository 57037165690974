<script>
import DetailFormGroup from '@/components/DetailFormGroup'
import { getAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import { handleDeep3 } from '@/command/dataProcess'

export default {
  name: 'detailColdAdd',
  data() {
    return {
      form: {},
      deviceList: [],
      levelArr: [], //五级联动
      storage_product_type: [],
      storage_facility: [],
      storage_facility_other: [],
    }
  },
  mounted() {
    const { creditCode, mainId, isEdit, dataSource } = this.$route.query
    if (isEdit === 'edit') {
      this.getDetail(dataSource)
    }
    // 获取存储类型字典
    getAction('/api/admin/dict/code/storage_product_type').then(
      (e) => (this.storage_product_type = e.map((e) => ({ name: e.name, value: e.id })))
    )
    // 获取冷库专业设备
    getAction('/api/admin/dict/code/storage_facility').then((e) => {
      this.storage_facility = e.map((e) => ({ name: e.name, value: e.name }))
    })
    // 获取冷库其他设备
    getAction('/api/admin/dict/code/storage_facility_other').then(
      (e) => (this.storage_facility_other = e.map((e) => ({ name: e.name, value: e.name })))
    )
    // 获取主体关联设备
    this.getDeviceList(creditCode)

    // 获取省市区
    getAction('/api/dataoperation/region/freeTree?level=4').then((res) => {
      this.levelArr = res && res.length > 0 && handleDeep3(res)
      this.$forceUpdate()
    })
  },
  methods: {
    getDeviceList(id) {
      getAction(`/api/dataoperation/baseMonitor/farmlandList?creditCode=${id}`).then((e) => {
        this.deviceList = e.map((e) => ({ name: e.fullName, value: e.id }))
      })
    },
    getDetail(dataSource) {
      let res = JSON.parse(dataSource)
      this.form = res
      if (res.area) {
        this.form.area = String(res.area)
      }
      if (res.measureArea) {
        this.form.measureArea = String(res.measureArea)
      }
      if (res.storageMoneyMin) {
        this.form.storageMoneyMin = String(res.storageMoneyMin)
      }
      if (res.storageMoneyMax) {
        this.form.storageMoneyMax = String(res.storageMoneyMax)
      }
      if (res.productType) {
        this.form.productType = res.productType.split(',')
      }
      this.form.dw = res.lng && `${res.lng}，${res.lat}`
      this.setValue('regionCode', [res.provinceCode, res.cityCode, res.areaCode, res.streetCode])
      this.$forceUpdate()
    },
    getInputForm() {
      return [
        {
          type: 'input',
          name: '冷库简称',
          key: 'storageName',
        },
        {
          type: 'inputNumber',
          name: '冷库面积',
          key: 'area',
        },
        {
          type: 'checkBoxButton',
          name: '冷库类型',
          key: 'storageType',
          typeData: [
            {
              name: '保鲜',
              value: '保鲜',
            },
            {
              name: '冷藏',
              value: '冷藏',
            },
            {
              name: '冷冻',
              value: '冷冻',
            },
          ],
        },
        {
          type: 'checkBoxButton',
          name: '产品类型',
          key: 'productType',
          typeData: this.storage_product_type,
        },
        {
          type: 'select',
          name: '关联设备',
          typeData: this.deviceList,
          placeholder: '请选择关联设备',
          key: 'areaId',
        },
      ]
    },
    getPrice() {
      if (this.form.moneyStatus == '0') {
        return null
      } else if (this.form.moneyStatus == '1') {
        return {
          type: 'input',
          key: 'storageMoneyMin',
          name: '价格',
          placeholder: '请输入单价',
        }
      } else if (this.form.moneyStatus == '2') {
        return {
          type: 'inputGroup',
          name: '价格',
          key: 'storageMoneyMin',
          datas: [
            {
              name: '最低价',
              key: 'storageMoneyMin',
              placeholder: '请输入最低价',
            },
            {
              name: '最高价',
              key: 'storageMoneyMax',
              placeholder: '请输入最高价',
            },
          ],
        }
      } else {
        return null
      }
    },
    // 获取经营设置form
    getBusinessSetUp() {
      return [
        {
          type: 'inputNumber',
          key: 'measureArea',
          name: '共享面积',
        },
        {
          key: 'status',
          name: '冷库状态',
          type: 'radioButton',
          typeData: [
            {
              name: '未共享',
              value: '0',
            },
            {
              name: '共享中',
              value: '1',
            },
            {
              name: '已关闭',
              value: '2',
            },
            {
              name: '未启用',
              value: '3',
            },
          ],
        },
        {
          type: 'timeRangePicker',
          keys: [
            {
              key: 'openHours',
              message: '请输入开始时间',
              name: '开始时间',
            },
            {
              key: 'closeHours',
              message: '请输入结束时间',
              name: '结束时间',
            },
          ],
          name: '营业时间',
          props: {
            showTime: true,
            mode: 'time',
          },
        },
        {
          name: '对外电话',
          type: 'input',
          key: 'phone',
          rules: [
            {
              ruleType: 'phone',
              message: '请输入正确手机号码',
            },
          ],
        },
        {
          name: '冷库租金',
          type: 'select',
          key: 'moneyStatus',
          typeData: [
            {
              name: '面议',
              value: '0',
            },
            {
              name: '固定值',
              value: '1',
            },
            {
              name: '区间',
              value: '2',
            },
          ],
        },
        this.getPrice(),
      ]
    },
    getColdStorageImg() {
      return {
        type: 'cardForm',
        title: '冷库图片上传',
        data: [
          {
            type: 'upload',
            key: 'image',
            label: '冷库图片',
            maxLength: 8,
          },
        ],
      }
    },
    onOpenMap() {
      apiTool.openTheMap({
        title: '打开地图',
        onSuccess: (data) => {
          this.form.lat = data.lat
          this.form.lng = data.lng
          this.form.dw = `${data.lng}，${data.lat}`
          this.getSearch(`${data.lng},${data.lat}`)
        },
      })
    },
    getAddress() {
      return [
        {
          name: '经纬度',
          type: 'row',
          cols: 12,
          gutter: 5,
          children: [
            {
              type: 'input',
              cols: 21,
              key: 'dw',
              label: '经纬度',
              placeholder: '请输入经纬度以中文逗号隔开',
              disabled: true,
            },
            {
              type: 'button',
              label: '定位',
              cols: 3,
              props: {
                type: 'primary',
                icon: 'environment',
                on: {
                  click: this.onOpenMap,
                },
              },
            },
          ],
        },
        {
          name: '地址',
          type: 'row',
          cols: 12,
          gutter: 5,
          children: [
            {
              type: 'cascader',
              cols: 10,
              key: 'regionCode',
              typeData: this.levelArr,
              label: '地址',
            },
            {
              type: 'input',
              cols: 14,
              key: 'address',
              label: '地址',
            },
          ],
        },
      ]
    },
    setValue(valKey, val) {
      this.form[valKey] = val
    },
    getSearch(value = '') {
      getAction(`/api/dataoperation/common/getLocation?location=${value}`).then((e) => {
        const { address, provinceCode, provinceName, cityCode, cityName, areaCode, areaName, streetCode, streetName } =
          e
        this.setValue('address', address)
        this.setValue('areaCode', areaCode)
        this.setValue('areaName', areaName)
        this.setValue('provinceName', provinceName)
        this.setValue('provinceCode', provinceCode)
        this.setValue('cityName', cityName)
        this.setValue('cityCode', cityCode)
        this.setValue('streetCode', streetCode || '')
        this.setValue('streetName', streetName || '')
        this.setValue('regionCode', [provinceCode, cityCode, areaCode, streetCode])
        this.$forceUpdate()
      })
    },
    getFormData() {
      return [
        {
          type: 'cardForm',
          title: '冷库信息',
          data: this.getInputForm(),
        },
        // 冷库经营设置
        {
          type: 'cardForm',
          title: '经营设置',
          data: this.getBusinessSetUp(),
        },
        // 获取冷库图片
        {
          type: 'cardForm',
          title: '冷库图片上传',
          data: [
            {
              type: 'upload',
              key: 'image',
              label: '冷库图片',
              maxLength: 8,
            },
          ],
        },
        // 冷库地址
        {
          type: 'cardForm',
          title: '冷库地址',
          data: this.getAddress(),
        },
        // 专业设备
        {
          type: 'cardForm',
          title: '专业设备',
          data: [
            {
              type: 'labelGroup',
              cols: 24,
              key: 'facility',
              typeData: [].concat(this.storage_facility),
            },
          ],
        },
        // 其他设备
        {
          type: 'cardForm',
          title: '其他设备',
          data: [
            {
              type: 'labelGroup',
              key: 'facilityOther',
              typeData: [].concat(this.storage_facility_other),
            },
          ],
        },
        // 农场简介
        {
          type: 'cardForm',
          title: '详情介绍',
          data: [
            {
              type: 'textArea',
              key: 'introduction',
              label: '详情介绍',
              cols: 24,
            },
          ],
        },
        // 共享须知
        {
          type: 'cardForm',
          title: '共享须知',
          data: [
            {
              type: 'textArea',
              key: 'remark',
              label: '共享须知',
              cols: 24,
            },
          ],
        },
      ]
    },
    getCategoryList(form) {
      if (form.productType && form.productType.length) {
        return form.productType.map((e) => {
          return {
            categoryId: e,
            categoryName: this.storage_product_type.find((el) => el.value == e).name,
          }
        })
      } else {
        return []
      }
    },
    getSubmitData(data) {
      const codeText = ['province', 'city', 'area', 'street']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = data[i]
      })
      return obj
    },
    submit(data) {
      let { mainId, isEdit, indexId } = this.$route.query
      let codeObj = (data.regionCode && this.getSubmitData(data.regionCode)) || {} //   let productType = this.getCategoryList(data)
      let params = {
        ...data,
        ...codeObj,
        productType: data.productType && data.productType.toString(),
        storageType: data.storageType && data.storageType.toString(),
      }

      if (isEdit === 'edit') {
        let arr = JSON.parse(localStorage.getItem('lkList')) || []
        arr[indexId] = params
        localStorage.setItem('lkList', JSON.stringify(arr))
        this.$router.push({ name: 'detailCold', query: { medianEdit: 'edit', mainId } })
      } else if (isEdit === 'add') {
        let arr = JSON.parse(localStorage.getItem('lkList')) || []
        arr.push(params)
        localStorage.setItem('lkList', JSON.stringify(arr))
        this.$router.push({ name: 'detailCold', query: { medianEdit: 'add', mainId } })
      }
    },
    getFoot() {
      return {
        left: [
          {
            name: '提交',
            sumbit: true,
            type: 'primary',
            onClick: this.submit,
          },
          {
            name: '取消',
            onClick: () => {
              this.$router.back()
            },
          },
        ],
      }
    },
  },
  render() {
    return <DetailFormGroup data={this.getFormData} foot={this.getFoot()} form={this.form} />
  },
}
</script>
